import React from "react";
import Meta from '../meta'
import { FaPenAlt } from "react-icons/fa" 
import  { dateFormatLong } from '../../utils/date'
import BlogPostType from "../../types/BlogPost";
import Img from "gatsby-image"

enum Mode {
    Thumbnail,
    Individual
}
export interface BlogPostProps {
    blogPost: BlogPostType
    showLink?: boolean
    excerpt?: boolean
    mode?: Mode
};

const BlogPost: React.FC<BlogPostProps> = (props) => (
    <div className="blog_post">
        <div className="card">
            <div className="card-content">
                <div className="media-content">
                    {props.blogPost.image && <Img fluid={props.blogPost.image} />}
                    <div className="title is-4 mt-3">
                        {props.blogPost.title}
                    </div>
                    <div className="subtitle is-6">{dateFormatLong(props.blogPost.publishedAt)}</div>
                    <div className="content" dangerouslySetInnerHTML={{__html: props.excerpt?props.blogPost.excerpt:props.blogPost.body}}></div>
                </div>
            </div>
        </div>
        {/* <Meta icon={FaPenAlt} publishedAt={props.blogPost.publishedAt} title={props.blogPost.title}  link={props.blogPost.link} />
        <div className="content" dangerouslySetInnerHTML={{__html: props.blogPost.body}}></div> */}
    </div>
)


export default BlogPost;
