import { parseISO } from 'date-fns/fp'
import BlogPost from '../types/BlogPost'


const parseBlogPost = (data: any) : BlogPost => {
  return   {
    title: data.frontmatter.title,
    body: data.html,
    publishedAt: new Date(parseISO(data.frontmatter.date)),
    link: data.fields.slug,
    excerpt: data.excerpt,
    image: data.frontmatter.image.childImageSharp.fluid
  }
}

export default parseBlogPost